<!-- ======= Publications Section ======= -->
<section id="publications" class="publications">
    <div class="container">
        <div class="section-title">
            <h1>Publications</h1>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="resume-item">
                  <ul>
                    <li>
                      Yury Niño Roa. <em><a href="https://www.infoq.com/articles/chaos-engineering-observability-visual-metaphors/">
                      <b>Chaos Engineering and Observability with Visual Metaphors</b></a></em>
                      In InfoQ Publications 2022.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Yury Niño Roa. <em><a href="https://www.infoq.com/news/2022/08/maturity-observability-code/">
                      <b>Programming Observability: Measuring the Maturity of Observability as Code
                      </b></a></em>
                      In InfoQ Publications 2022.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Yury Niño Roa. <em><a href="https://thechief.io/c/blameless/yury-ni%C3%B1o-roa-shares-her-insights-on-chaos-engineering-and-sre/">
                      <b>Insights on Chaos Engineering and SRE</b></a></em>
                      In Blameless Publications 2022.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Yury Niño Roa. <em><a href="https://www.gremlin.com/blog/yury-nino-roa-lightning-talk-hot-recipes-for-building-chaos-experiments-chaos-conf-2019/">
                      <b>Lightning Talk: Hot Recipes for Building Chaos Experiments - Chaos Conf 2019</b></a></em>
                      In Gremlin Publications 2022.
                    </li>
                  </ul>
                  <ul>
                      <li>
                          Rodriguez Christian, Yury Niño and Jairo Aponte. <em><b>Survey and Research
                          Trends in Mining Software Repositories.</b></em> In Research Topics in
                          Software Evolution and Maintenance. Collection Universidad Nacional de Colombia.
                          ISBN: 978-958-761-162-5 (paperback). 978-958-761-163-2 (print on demand).
                          978-958-761-167-0 (e-book). paperback; 16,7 x 24 cm; 256 pages; 2012.
                      </li>
                  </ul>
                  <ul>
                      <li>
                          Niño Yury y Aponte Jairo. <b><em>DevMeter: una Herramienta que mide la
                          Contribución de los Desarrolladores.</em></b>XXXVI Conferencia
                          Latinoamericana de Informática (XXXVI CLEI). Asunción, Paraguay, 2010.
                      </li>
                  </ul>
                  <ul>
                      <li>
                          Niño Yury, Aponte Jario, Montaño David y Collazos Victor. <b><em>Aplicación
                          de un Framework de Evaluación de Plugins de Eclipse para Visualización
                          de Sofware.</em></b> IV Congreso Colombiano de Computación. Bucaramanga, Colombia, 2009.
                      </li>
                  </ul>
                </div>

            </div>
        </div>

        <br/>
        <br />
        <br />

        <div class="section-title">
            <h1>Interviews</h1>
        </div>
        <div>
            <div>
                <div class="resume-item">
                    <ul>
                        <li>
                            <a href="https://open.spotify.com/show/137kWOBdiS8lA97kzYJbKH">
                                Podcast AWS LATAM</a>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <a href="https://www.blameless.com/blog/interview-with-yury-nino-roa">
                                Insights on Chaos Engineering and SRE</a>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <a href="https://www.infoq.com/news/2020/10/chaos-conf-2020/">
                                ChaosConf Q&A: Adrian Cockcroft & Yury Niño Roa 2020</a>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <a href="https://open.spotify.com/episode/682u0Rba5sQBf4ikD7umyE">
                                ¿Qué es la Ingeniería del Caos? Oswaldo Alvarez ft Yury Niño</a>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <a href="https://www.infoq.com/chaos-conf-2019/">
                                ChaosConf Q&A: Presenters 2019</a>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <a href="https://open.spotify.com/episode/5g4byX27W4HtueAvzBMYoq?si=njMHximORCqbreT8icJVQQ">
                                Entrevista DevOpsDays Guadalajara 2020</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Publications Section -->
