import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.css',
    '../assets/vendor/bootstrap/css/bootstrap.min.css',
    '../assets/vendor/icofont/icofont.min.css',
    '../assets/vendor/remixicon/remixicon.css',
    '../assets/vendor/owl.carousel/assets/owl.carousel.min.css',
    '../assets/vendor/boxicons/css/boxicons.min.css',
    '../assets/vendor/venobox/venobox.css',
    '../assets/css/style.css',
  ],
})
export class AppComponent {
  title = 'web-applications-yurynino-site';
}
