import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ResumeComponent } from './resume/resume.component';
import { ConferencesComponent } from './conferences/conferences.component';
import { PublicationsComponent } from './publications/publications.component';
import { DrawingComponent } from './drawing/drawing.component';
import { QuotesComponent } from './quotes/quotes.component';

@NgModule({
  declarations: [AppComponent, ResumeComponent, ConferencesComponent, PublicationsComponent, DrawingComponent, QuotesComponent],
  imports: [BrowserModule, AppRoutingModule],
  providers: [],
  bootstrap: [AppComponent, ResumeComponent, ConferencesComponent, PublicationsComponent, DrawingComponent, QuotesComponent],
})
export class AppModule {}
