<!-- ======= Conferences Section ======= -->
<section id="conferences" class="conferences">
  <div class="container">
    <div class="section-title">
      <h1>Conferences</h1>
    </div>

    <div class="row">

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/monitoring-dora-devops-metrics-with-datadog">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Datadog Dash 2022</a></h4>
          <p>
            Monitoring DORA DevOps Metrics with Datadog
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/dora-devops-applied-to-google-cloud">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Manizales Tech Talks 2022</a></h4>
          <p>
            DORA DevOps applied to Google Cloud
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/observability-is-programmed">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">&nbsp;&nbsp;QCon Plus 2022&nbsp;&nbsp;</a></h4>
          <p>
            Observability is Programmed. Observability as Code
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/introduction-to-non-abstract-large-design-systems">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Payu Latam 2022</a></h4>
          <p>
            Introduction to Non-Abstract Large Design Systems
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/what-can-engineers-learn-from-aviation-and-space">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Women Techmakers 2022</a></h4>
          <p>
            What can Engineers learn from Aviation & Space?
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/sketcnotes-the-art-of-visual-notes">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SLOConf 2022&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></h4>
          <p>
            .                  SLOS for Healthcare Industry                  .
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/results-of-comparison-traditional-vs-metaphors">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">QConf London 2022</a></h4>
          <p>
            Chaos Engineering and Observability with Visual Metaphors
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/healthcare-operational-dashboards">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chaos Carnival 2022&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></h4>
          <p>
            .       Healthcare Operational Dashboards      .
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/why-software-developers-should-build-a-resilience-culture-based-on-chaos-engineering-595eb944-b758-400d-8272-0ae6575e81d1">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Buildstuff Conference</a></h4>
          <p>
            Why Software Developers should build a Resilience Culture based on Chaos Engineering
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/system-design-in-gcp-waf-with-terraform">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">&nbsp;&nbsp;&nbsp;HashiTalks 2021&nbsp;&nbsp;&nbsp;</a></h4>
          <p>
            .    System Design in GCP WAF with Terraform   .
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/sre-slis-slos-and-slas">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">&nbsp;&nbsp;SRE Comunidad Español&nbsp;</a></h4>
          <p>
            .   SLIs, SLOs & SLAs en SRE   .
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/distributed-architectures-with-genetic-algorithms">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">EventSourcing Live</a></h4>
          <p>
            Distributed Architectures with Genetic Algorithms
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/non-abstract-large-systems-design">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">DevOps Days Medellín</a></h4>
          <p>
            Developing problem-solving skills to design planet-scale systems with NALSD
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/sketcnotes-the-art-of-visual-notes">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">&nbsp;Sketchnotes ADL Meetup&nbsp;</a></h4>
          <p>
            . Sketchnotes: The Art of Visual Notes .
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/verifying-unknown-conditions-with-chaos-engineering">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">AWS Perú</a></h4>
          <p>
            Verifying Unknown Conditions with Chaos Engineering
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/learning-algorithms-through-puzzle-solving">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">&nbsp;&nbsp;Grupo Algoritmos&nbsp;&nbsp;</a></h4>
          <p>
            .  Learning Algorithms through Puzzle Solving  .
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/supporting-sre-with-azure-services">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Global Azure México</a></h4>
          <p>
            Supporting Site Relibility Engineers with Azure Services
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/why-software-developers-should-build-a-resilience-culture-based-on-chaos-engineering">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">DWX21</a></h4>
          <p>
            Why software developers should build a resilience culture based on chaos engineering
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/maturity-model-for-slos">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">&nbsp;&nbsp;SLOConference for SRE&nbsp;&nbsp;&nbsp;</a></h4>
          <p>
            .      Maturity Model for SLOs      .
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/machine-learning-to-predict-chaos">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">&nbsp;&nbsp;&nbsp;Chaos Week Paris 2021&nbsp;&nbsp;&nbsp;</a></h4>
          <p>
            Machine Learning to Predict Chaos
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/chaos-gamedays-for-training-engineering-teams">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Ciberseguridad Scotiabank 2021</a></h4>
          <p>
            Security Chaos Gamedays for Training Engineering Teams
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/sre-heroes-and-villains">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">CNCF Meetup 2021</a></h4>
          <p>
            .Site Reliability Engineering Heroes & Villains.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/securing-the-cloud-empowering-developers-to-practice-sce">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Chaos Conf42 2021</a></h4>
          <p>
            Securing the Cloud Empowering Developers to practice SCE
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/tulp-integrating-ai-and-ce-to-learn-from-incidents">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Chaos Carnival 2021</a></h4>
          <p>
            Tulp: Integrating AI and CE to Learn from Incidents
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/combining-chaos-plus-observability-plus-resilience-to-get-chaos-engineering">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Gotopia ChaosDay 2021</a></h4>
          <p>
            Chaos & Observability & Resilience to get Chaos Engineering
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://www.youtube.com/watch?v=o2awPB0zbvc">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">ArqConf Summit 2020</a></h4>
          <p>
            Chaos + Observability + Resilience = Chaos Engineering
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/tulp-integrating-machine-learning-and-chaos-engineering">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">MIM Expo 2020</a></h4>
          <p>
            Tulp: Integrating Artificial Intelligence and Chaos Engineering to Learn from the Incidents.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://www.youtube.com/watch?v=iF_uoPatExs">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">HashiTalks Latam 2020</a></h4>
          <p>
            Technical Program Management: The art of keeping promises with Terraform
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/chaos-engineering-considering-failures-from-development">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">DeveloperWeek Global 2020</a></h4>
          <p>
            Chaos Engineering from Software Development Life Cycle
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/resilience-cloud-providers-promises">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">DevFest 2020</a></h4>
          <p>
            Resilience: The promise of cloud providers. Keeping promises with Chaos.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://www.chaosconf.io/chaosconf/lineup">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">ChaosConf 2020</a></h4>
          <p>
            Convergence of Chaos Engineering and Revolutionary Technology
            Techniques.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://devopsdaysbogota.org/ponentes">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">DevOpsDays Bogotá 2020</a></h4>
          <p>
            Preparing SRE Teams to manage high severity incidents with Chaos
            Engineering.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a
            href="https://speakerdeck.com/yurynino/training-engineering-teams-with-chaos-engineering"
          >
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Catedra Egresados Unal 2020</a></h4>
          <p>Training Engineering Teams with Chaos Engineering.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a
            href="https://springone.io/post-event/sessions/revealing-resilience-vulnerabilities-in-spring-boot-architectures"
          >
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">SpringOne 2020</a></h4>
          <p>
            Revealing Resilience Vulnerabilities in SpringBoot Architectures.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://devopsindiasummit.com/#event_agenda">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">DOIS20</a></h4>
          <p>
            Security Chaos Engineering: Cyberattacks are the New Challenge for
            Chaos Engineering.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a
            href="https://speakerdeck.com/yurynino/easy-recipes-for-building-resilience-with-chaos-engineering"
          >
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4>
            <a href="">Ekoparty Conference 2020</a>
          </h4>
          <p>Easy Recipes for Building Resilience with Chaos Engineering.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://devopsdays.io/ponentes">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">DevOpsDay Medellín 2020</a></h4>
          <p>
            Chaos AntiPatterns: Finding Commons in Incidents to Automate
            Postmortems.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://www.youtube.com/watch?v=hnoahDG03P0">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">SRE Conf42 2020</a></h4>
          <p>
            Security Chaos Engineering: Considerations for Gamedays when the
            Experiments are Cyberattacks.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a
            href="https://speakerdeck.com/yurynino/designing-security-chaos-gamedays"
          >
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4>
            <a href="">8.8 Computer Security Conference 2020</a>
          </h4>
          <p>Designing Security Chaos Gamedays to prepare SRE Teams.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a
            href="https://www.youtube.com/watch?v=ggry4Uan0cE&list=PL3vw0-L4r4TnAZPGYFI13kZlSxeCAy3w3&index=5"
          >
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">AWS Community Days 2020</a></h4>
          <p>Training Teams with Chaos Engineering On AWS Fargate.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a
            href="https://speakerdeck.com/yurynino/resilience-engineering-why-break-your-software-with-chaos-engineering"
          >
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">WomenTech 2020</a></h4>
          <p>
            Resilience Engineering:Why break your software with Chaos
            Engineering.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a
            href="https://siberx.org/event/siberxchange-live-online-summit/#Track-22"
          >
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">siberXchange 2020</a></h4>
          <p>Panel Women in Cybersecurity - Mentorship and Careers</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a
            href="https://speakerdeck.com/yurynino/site-reliability-engineering-webinar"
          >
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">DevOps Webinar 2020</a></h4>
          <p>Introduction to Site Reliability Engineering & DevOps.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://www.youtube.com/watch?v=wxUHpKDal6Q">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">DerpCon 2020</a></h4>
          <p>
            Building Secure Systems using Security Chaos Engineering and
            Immunity.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://devopsindiasummit.com/devops-for-executive/">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Panel SRE 2020</a></h4>
          <p>Significant Thoughts On SRE In Current And Future.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a
            href="https://speakerdeck.com/yurynino/how-to-start-with-chaos-engineering-for-organizations"
          >
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Quarantine Talks 2020</a></h4>
          <p>How to start with Chaos Engineering for Organizations.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a
            href="https://speakerdeck.com/yurynino/how-to-start-with-chaos-engineering-for-organizations"
          >
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">African Women 2020</a></h4>
          <p>Technology & Business: How to choose your programming language?</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://www.youtube.com/watch?v=tO-Vu0oruJ4">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Apache Spark 2020</a></h4>
          <p>
            Testing high availability in Apache Spark with Chaos Engineering.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://www.youtube.com/watch?v=tO-Vu0oruJ4">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">DevOpsDays GLD 2020</a></h4>
          <p>Chaos Engineering Build Immunity in Distributed Systems.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://www.youtube.com/watch?v=rTdzzdJSAC8">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">HashiTalks 2020</a></h4>
          <p>Automating Chaos Engineering Gamedays with Terraform.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://www.youtube.com/watch?v=JtLrlDNdJzg">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Chaos Conf42 2020</a></h4>
          <p>Postmortem Culture: Learning from Failures.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a
            href="https://speakerdeck.com/yurynino/chaos-engineering-proof-of-hypothesis-in-production"
          >
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">BoyaConf 2019</a></h4>
          <p>Chaos Engineering: Proof of Hypothesis in Production</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://www.youtube.com/watch?v=Ap4Fg_lk8qM">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">ChaosConf 2019</a></h4>
          <p>
            &nbsp;&nbsp;Hot Recipies for Building Chaos Experiments.&nbsp;&nbsp;
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://speakerdeck.com/yurynino/security-chaos-engineering">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">BSides 2019</a></h4>
          <p>
            Security Chaos Engineering: Injecting failures for mitigating
            vulnerabilities.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a
            href="https://speakerdeck.com/yurynino/chaos-engineering-in-containers"
          >
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">VMware Kubernetes 2019</a></h4>
          <p>
            &nbsp;&nbsp;Practicing Chaos Engineering in Containers.&nbsp;&nbsp;
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a
            href="https://speakerdeck.com/yurynino/chaos-engineering-the-discipline-based-on-failure-and-resilience"
          >
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">CampusParty 2019</a></h4>
          <p>
            Chaos Engineering: The Discipline based in Failure and Resilience.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a
            href="https://speakerdeck.com/yurynino/chaos-engineering-resilience-strategies-in-cloud-architectures-294311bd-e946-44fa-b9f5-41a7b4b8a3af"
          >
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">AWS Cloud Girls 2019</a></h4>
          <p>
            Chaos Engineering: Resilience Strategies in Cloud Architectures.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://www.youtube.com/watch?v=Bqgkgg0SMRA&t=204s">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">DevOpsDays Bogotá 2019</a></h4>
          <p>My journey planning my firts Chaos Game Day.</p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://www.youtube.com/watch?v=_z6IIUcBf-M">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">ScaleConf 2019</a></h4>
          <p>
            Chaos Engineering: Injecting failure for building resilience in
            systems.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://www.youtube.com/watch?v=4Lv63ABNCok&t=280s">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Egresados 2019</a></h4>
          <p>
            Ingeniería del Caos: ¿Qué es? ¿Para qué sirve? y ¿Cómo se aplica?
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
        <div class="icon-box">
          <a href="https://www.youtube.com/watch?v=N1vG-jnDV-s">
            <div class="icon"><i class="bx bx-video"></i></div>
          </a>
          <h4><a href="">Bogotá JVM 2019</a></h4>
          <p>Java virtual Machine Meetup: Plan software to fail!</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Conferences Section -->
