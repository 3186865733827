<!-- ======= Drawing Section ======= -->
<section id="drawing" class="drawing">

   <div class="container">
    <div class="section-title">
      <h1>Coffee Power</h1>
    </div>

     <div class="row drawing-container">
       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_40.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>Data Navidad</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_40.png" data-gall="drawingGallery" class="venobox"
                  title="Data Navidad"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>

       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_39.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>DevOps Navidad</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_39.png" data-gall="drawingGallery" class="venobox"
                  title="DevOps Navidad"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>

       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_38.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>Sistemas Embebidos</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_38.png" data-gall="drawingGallery" class="venobox"
                  title="Sistemas Embebidos"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>

       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_37.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>Comunidad</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_37.png" data-gall="drawingGallery" class="venobox"
                  title="Comunidad"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>
     </div>

     <div class="row drawing-container">
       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_36.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>Data Navidad</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_36.png" data-gall="drawingGallery" class="venobox"
                  title="Data Navidad"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>

       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_35.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>DevOps Navidad</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_35.png" data-gall="drawingGallery" class="venobox"
                  title="DevOps Navidad"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>

       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_34.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>Sistemas Embebidos</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_34.png" data-gall="drawingGallery" class="venobox"
                  title="Sistemas Embebidos"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>

       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_33.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>Comunidad</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_33.png" data-gall="drawingGallery" class="venobox"
                  title="Comunidad"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>
     </div>

     <div class="row drawing-container">
       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_32.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>Data Navidad</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_32.png" data-gall="drawingGallery" class="venobox"
                  title="Data Navidad"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>

       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_31.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>DevOps Navidad</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_31.png" data-gall="drawingGallery" class="venobox"
                  title="DevOps Navidad"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>

       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_30.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>Sistemas Embebidos</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_30.png" data-gall="drawingGallery" class="venobox"
                  title="Sistemas Embebidos"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>

       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_29.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>Comunidad</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_29.png" data-gall="drawingGallery" class="venobox"
                  title="Comunidad"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>
     </div>

     <div class="row drawing-container">
       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_28.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>Data Navidad</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_28.png" data-gall="drawingGallery" class="venobox"
                  title="Data Navidad"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>

       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_27.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>DevOps Navidad</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_27.png" data-gall="drawingGallery" class="venobox"
                  title="DevOps Navidad"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>

       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_26.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>Sistemas Embebidos</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_26.png" data-gall="drawingGallery" class="venobox"
                  title="Sistemas Embebidos"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>

       <div class="col-lg-3 col-md-6 drawing-item filter-app">
         <div class="drawing-wrap">
           <img src="assets/img/coffee/coffee_25.png" class="img-fluid" alt="" />
           <div class="drawing-info">
             <h4>Comunidad</h4>
             <div class="drawing-links">
               <a href="assets/img/drawing/coffee_25.png" data-gall="drawingGallery" class="venobox"
                  title="Comunidad"><i class="bx bx-plus"></i></a>
             </div>
           </div>
         </div>
       </div>
     </div>

    <div class="row drawing-container">
      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_24.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Data Navidad</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_24.png" data-gall="drawingGallery" class="venobox"
                title="Data Navidad"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_23.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>DevOps Navidad</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_23.png" data-gall="drawingGallery" class="venobox"
                title="DevOps Navidad"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_22.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Sistemas Embebidos</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_22.png" data-gall="drawingGallery" class="venobox"
                title="Sistemas Embebidos"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_21.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Comunidad</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_21.png" data-gall="drawingGallery" class="venobox"
                title="Comunidad"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row drawing-container">
      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_20.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Impostor</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_20.png" data-gall="drawingGallery" class="venobox"
                title="Impostor"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_19.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Perfiles</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_19.png" data-gall="drawingGallery" class="venobox"
                title="Perfiles"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_18.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Cloud Native</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_18.png" data-gall="drawingGallery" class="venobox"
                title="Cloud Native"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_17.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Flutter</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_17.png" data-gall="drawingGallery" class="venobox"
                title="Flutter"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row drawing-container">
      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_16.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>MLOps</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_16.png" data-gall="drawingGallery" class="venobox"
                title="MLOps"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_15.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Comunicación</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_15.png" data-gall="drawingGallery" class="venobox"
                title="Comunicación"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_14.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>SEO</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_14.png" data-gall="drawingGallery" class="venobox"
                title="SEO"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_13.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Pecados Desarrollo</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_13.png" data-gall="drawingGallery" class="venobox"
                title="Pecados Desarrollo"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row drawing-container">
      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_12.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Estrategia Datos</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_12.png" data-gall="drawingGallery" class="venobox"
                title="Estrategia Datos"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_11.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>DataOps</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_11.png" data-gall="drawingGallery" class="venobox"
                title="DataOps"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_10.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Code Venezuela</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_10.png" data-gall="drawingGallery" class="venobox"
                title="Code Venezuela"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_9.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Equipos Distribuidos</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_9.png" data-gall="drawingGallery" class="venobox"
                title="Equipos Distribuidos"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row drawing-container">
      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_8.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>OKRs</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_8.png" data-gall="drawingGallery" class="venobox"
                title="OKRs"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_7.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Trabajar Extranjero</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_7.png" data-gall="drawingGallery" class="venobox"
                title="Trabajar Extranjero"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_6.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Blockchain</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/coffee_6.png" data-gall="drawingGallery" class="venobox"
                title="Blockchain"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_5.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Kubernetes</h4>
            <div class="drawing-links">
              <a href="assets/img/coffee/coffee_5.png" data-gall="drawingGallery" class="venobox" title="Kubernetes"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row drawing-container">
      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_4.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Third Party</h4>
            <div class="drawing-links">
              <a href="assets/img/coffee/coffee_4.png" data-gall="drawingGallery" class="venobox" title="Third Party"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_3.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>NoCode</h4>
            <div class="drawing-links">
              <a href="assets/img/coffee/coffee_3.png" data-gall="drawingGallery" class="venobox" title="NoCode"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_2.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>CTO</h4>
            <div class="drawing-links">
              <a href="assets/img/coffee/coffee_2.png" data-gall="drawingGallery" class="venobox" title="CTO"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/coffee/coffee_1.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Salud Programadores</h4>
            <div class="drawing-links">
              <a href="assets/img/coffee/coffee_1.png" data-gall="drawingGallery" class="venobox" title="Salud Programadores"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="section-title">
      <h1>Drawings</h1>
    </div>

    <div class="row drawing-container">

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_37.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Google Cloud 2</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_37.png" data-gall="drawingGallery" class="venobox"
                title="Google Cloud 2"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
          <div class="drawing-wrap">
            <img src="assets/img/drawing/drawing_36.png" class="img-fluid" alt="" />
            <div class="drawing-info">
              <h4>Google Cloud 1</h4>
              <div class="drawing-links">
                <a href="assets/img/drawing/drawing_36.png" data-gall="drawingGallery" class="venobox"
                  title="Google Cloud 1"><i class="bx bx-plus"></i></a>
              </div>
            </div>
          </div>
        </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_33.JPG" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Alejandro Gaviria</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_33.JPG" data-gall="drawingGallery" class="venobox" title="Alejandro Gaviria"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_32.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Salud Programadores 2</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_32.png" data-gall="drawingGallery" class="venobox" title="Salud Programadores 2"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_31.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Security Chaos Engineering</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_31.jpg" data-gall="drawingGallery" class="venobox" title="Security Chaos Engineering"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_29.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Soft Skills</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_29.jpg" data-gall="drawingGallery" class="venobox" title="Soft Skills"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_28.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Azure Services</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_28.jpg" data-gall="drawingGallery" class="venobox" title="Azure Services"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_27.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Software Architecture</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_27.jpg" data-gall="drawingGallery" class="venobox" title="Software Architecture"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_23.jpeg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Coffee Power</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_23.jpeg" data-gall="drawingGallery" class="venobox" title="Cofee Power"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_22.jpeg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Feed Phil</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_22.jpeg" data-gall="drawingGallery" class="venobox"
                title="Feed Phil"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_21.jpeg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Base of Sex</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_21.jpeg" data-gall="drawingGallery" class="venobox"
                title="Base of Sex"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_19.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>ChaosConf</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_19.png" data-gall="drawingGallery" class="venobox"
                title="ChaosConf"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_17.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Security</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_17.png" data-gall="drawingGallery" class="venobox" title="Security"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_16.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Resilience</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_16.jpg" data-gall="drawingGallery" class="venobox"
                title="Resilience"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_15.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>CRISPR</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_15.jpg" data-gall="drawingGallery" class="venobox" title="CRISPR"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_13.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Customers</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_13.jpg" data-gall="drawingGallery" class="venobox"
                title="Customers"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-web">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_12.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>West World</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_12.jpg" data-gall="drawingGallery" class="venobox"
                title="West World"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_11.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Expertise</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_11.jpg" data-gall="drawingGallery" class="venobox"
                title="Expertise"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-card">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_10.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>WW II</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_10.jpg" data-gall="drawingGallery" class="venobox" title="WW II"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-web">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_9.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>1917</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_9.jpg" data-gall="drawingGallery" class="venobox" title="1917"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_8.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Creativity</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_8.jpg" data-gall="drawingGallery" class="venobox"
                title="Creativity"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 drawing-item filter-card">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_7.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Postmortems</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_7.jpg" data-gall="drawingGallery" class="venobox"
                title="Postmortems"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row drawing-container">

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_39.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Healthcare</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_39.png" data-gall="drawingGallery" class="venobox" title="Healthcare"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_38.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Fault Injection</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_38.png" data-gall="drawingGallery" class="venobox" title="Fault Injection"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_35.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>DOIS</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_35.png" data-gall="drawingGallery" class="venobox" title="DOIS"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_34.jpeg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>CIDR</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_34.jpeg" data-gall="drawingGallery" class="venobox"
                title="CIDR"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_30.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>AWS re:Invent</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_30.jpg" data-gall="drawingGallery" class="venobox" title="AWS re:Invent"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_26.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Mujer Colombiana</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_26.jpg" data-gall="drawingGallery" class="venobox" title="Mujer Colombiana"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_25.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Azure VPN</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_25.jpg" data-gall="drawingGallery" class="venobox" title="Azure VPN"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_24.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Azure</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_24.png" data-gall="drawingGallery" class="venobox" title="Azure"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_20.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Trainer</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_20.png" data-gall="drawingGallery" class="venobox" title="Trainer"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_18.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>SRE Training</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_18.png" data-gall="drawingGallery" class="venobox" title="Training"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_14.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Error</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_14.png" data-gall="drawingGallery" class="venobox" title="Error"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_6.png" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Failures</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_6.png" data-gall="drawingGallery" class="venobox" title="Failures"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_5.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>SRE</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_5.jpg" data-gall="drawingGallery" class="venobox" title="SRE"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_4.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Ambassador</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_4.jpg" data-gall="drawingGallery" class="venobox"
                title="Amsbassador"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_3.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Sidecar</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_3.jpg" data-gall="drawingGallery" class="venobox" title="Sidecar"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_2.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Gamedays</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_2.jpg" data-gall="drawingGallery" class="venobox" title="Gamedays"><i
                  class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/drawing/drawing_1.jpg" class="img-fluid" alt="" />
          <div class="drawing-info">
            <h4>Statistics</h4>
            <div class="drawing-links">
              <a href="assets/img/drawing/drawing_1.jpg" data-gall="drawingGallery" class="venobox"
                title="Statistics"><i class="bx bx-plus"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End drawing Section -->
</section>
