import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drawing',
  templateUrl: './drawing.component.html',
  styleUrls: [
    './drawing.component.css',
    '../../assets/vendor/bootstrap/css/bootstrap.min.css',
    '../../assets/vendor/icofont/icofont.min.css',
    '../../assets/vendor/remixicon/remixicon.css',
    '../../assets/vendor/owl.carousel/assets/owl.carousel.min.css',
    '../../assets/vendor/boxicons/css/boxicons.min.css',
    '../../assets/vendor/venobox/venobox.css',
    '../../assets/css/style.css',
  ],
})
export class DrawingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
