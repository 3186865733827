<!-- ======= Drawing Section ======= -->
<section id="quotes" class="quotes">

  <div class="container">
    <div class="section-title">
      <h1>Quotes</h1>
    </div>

    <div class="row drawing-container">

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_85.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_84.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_83.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_82.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_81.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_80.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_79.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_78.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_77.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_76.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_75.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_74.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_73.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_72.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_71.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_70.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_69.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_68.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_67.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_66.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_65.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_64.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_63.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_62.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_61.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_60.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_59.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_58.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_57.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_56.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_55.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_54.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_53.png" class="img-fluid" alt="" />
        </div>
      </div>
      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_52.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_51.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_50.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_49.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_48.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_47.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_46.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_45.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_44.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_43.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_42.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_41.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_40.png" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_39.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_38.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_37.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_36.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_35.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_34.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_33.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_32.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_31.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_30.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_29.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_28.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_27.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_26.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_25.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_24.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_23.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_22.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_21.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_20.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_19.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_18.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_17.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_16.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_15.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_14.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_13.JPG" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_12.jpg" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_11.jpg" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_10.jpg" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_9.jpg" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_8.jpg" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_7.jpg" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_6.jpg" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_5.jpg" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_4.jpg" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_3.jpg" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_2.jpg" class="img-fluid" alt="" />
        </div>
      </div>

      <div class="col-lg-3 col-md-6 drawing-item filter-app">
        <div class="drawing-wrap">
          <img src="assets/img/quotes/quote_1.jpg" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>

</section>
