<!-- ======= Resume Section ======= -->
<section id="resume" class="resume">
  <div class="container">
    <div class="section-title">
      <h1>Check My Resume</h1>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <h3 class="resume-title">Sumary</h3>
        <div class="resume-item pb-0">
          <p>
            <em
              >Cloud Infrastructure Engineer @Google with focus on AppDev, Networking and SRE.
            </em>
          </p>
          <p>
            <em
              >Software and Chaos Engineer Advocate with 13+ years of experience designing,
              implementing and managing the development of software applications using agile
              methodologies. 1+ years of hands-on experience supporting, automating and
              optimizing mission-critical deployments.
            </em>
          </p>
          <p>
            <em
              >Professor of Software Engineering and Researcher with interest in solving performance,
              resilience and reliability issues, using chaos engineering and studying human
              factors, safety on systems and lack of monitoring and observability.</em
            >
          </p>
        </div>

        <h3 class="resume-title">Education</h3>
        <div class="resume-item">
          <h4>Master in Computer Science</h4>
          <h5>2008 - 2011</h5>
          <p><em>Universidad Nacional de Colombia</em></p>
          <p>
            I created a model that allows measuring the contributions of the
            individuals in open source object oriented projects. I implemented a
            tool named DevMeter that measured the contributions of the
            developers using software repositories mining.
          </p>
        </div>
        <div class="resume-item">
          <h4>Bachelor of Systems Engineering</h4>
          <h5>2003 - 2008</h5>
          <p><em>Universidad Nacional de Colombia</em></p>
          <p>
            Solid foundation in both theoretical and practical computer science.
            I was focused in software engineering and developing web
            applications with an special interest in databases and processing
            unstructured information.
          </p>
        </div>
        <div class="resume-item">
          <h4>Certifications</h4>
          <div>
            <ul>
              <li>
                <a href="https://www.credential.net/5ff9cd02-bc10-47f7-87b4-1e40a815ddf3">Google Cloud Professional Architect</a>
              </li>
              <li>
                <a href="https://www.credential.net/fd481a38-5430-4a49-b5f6-d9467b0a5f51?key=43d5c4a9584f264fb2c0c84a1160a683d2801259b7303f99fb0754b39282c53e">Google Associate Cloud Engineer</a>
              </li>
              <li>
                <a
                  href="https://www.credential.net/86e8fe06-ae68-4f4f-aadb-ca2dbb20cb34?key=40e584e7b253d1994fe4564ba528c172a8254f6e68d2acf41169c85479d1469b">Google Cloud Digital Leader</a>
              </li>
              <li>
                <a href="https://www.credential.net/fe40e486-a3c3-459a-964f-480d16de1f86#gs.6k7l1c">Chaos Engineering Practitioner (CEP)</a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/amazon-web-services/">AWS Certified Cloud Practitioner (CLF)</a>
              </li>
              <li>
                <a href="https://www.youracclaim.com/badges/2556ddf2-f8e6-4092-bcce-2f7644161e95">Microsoft Azure Fundamentals (AZ-900)</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="resume-item">
          <h4>Certificates</h4>
          <div>
            <ul>
              <li>
                <a href="https://drive.google.com/file/d/1eI9bFoCxi5lLp06pK5pqPV8qILwgYzq5/view?usp=sharing">AWS Security Best Practices</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1Yd0qtL-fNyoJobeS70H0vAwVwTc9fkCB/view?usp=sharing">AWS Technical - Internet of Things</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1_6rFcQAchD2i0MER7JM96jTigR6gQFxE/view?usp=sharing">AWS Business - Internet of Things</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1OMdIzF-_G-44Lrs9dnoLC7mSmkcU6SSK/view?usp=sharing">AWS Technical - Machine Learning</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1es08Q10NwCpXFC0cEiQ3-pO9gJxIcDWt/view?usp=sharing">AWS Business - Machine Learning</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1Yd0qtL-fNyoJobeS70H0vAwVwTc9fkCB/view?usp=sharing">AWS Technical - Internet of Things</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1_6rFcQAchD2i0MER7JM96jTigR6gQFxE/view?usp=sharing">AWS Business - Internet of Things</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/14OcONPqrq_qUKvPFnW0XfsQB7bVbNg97/view?usp=sharing">AWS Containers</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1QF9aE5XRK__fMKn1BPpcBde0lgXb2RBy/view?usp=sharing">AWS Data Analytics</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1ryU7kvobScxdHm1D3AQEiL4btpWk9aNn/view?usp=sharing">AWS Weel Architected Framework</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/10eAfAiSt5stqMNTDf3hSO7pCEMgH_m0F/view?usp=sharing">AWS Technical - Foundations</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1fNOKIosip7pjHd52dD2fPoF8kQ8P56q3/view?usp=sharing">AWS Business - Foundations</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <h3 class="resume-title">Professional Experience</h3>
        <div class="resume-item">
          <h4>Cloud Infrastructure Engineer</h4>
          <h5>2021 - Present</h5>
          <p><em>Google</em></p>
          <div>
            <ul>
              <li>
                Provide technical guidance to customers adopting Google Cloud Platform services.
              </li>
              <li>
                Guide the usage of best practices on secure foundational cloud implementations,
                automated provisioning of infrastructure and applications, cloud-ready application
                architectures, and more.
              </li>
              <li>
                Ensure that customers have the best experience in migrating, building,
                modernizing, and maintaining applications in Google Cloud Platform.
              </li>
              <li>
                Work closely with Product Management and Product Engineering to drive excellence in
                Google Cloud products and features.
              </li>
            </ul>
          </div>
        </div>
        <div class="resume-item">
          <h4>Senior Site Reliability Engineer</h4>
          <h5>2019 - 2021</h5>
          <p><em>ADL Digital Labs</em></p>
          <div>
            <ul>
              <li>
                Support to SRE Management in the design of solutions that
                respond to non-functional requirements such as reliability,
                availability, performance, resilience and security.
              </li>
              <li>
                My daily responsibilities include: running SRE onboardings,
                setting SLOs, SLAs, and SLIs, improving the on-call incident
                response process, opening up communication channels and
                establishing SRE practices.
              </li>
              <li>
                Lead Chaos Engineering Adoption in Software Engineering Teams,
                through workshops, ideation, and tooling: implementing
                automation tools and frameworks (CI/CD pipelines) that require
                Failures Injection.
              </li>
            </ul>
          </div>
        </div>
        <div class="resume-item">
          <h4>Technical Lead</h4>
          <h5>2018 - 2019</h5>
          <p><em>Scotiabank Colpatria</em></p>
          <div>
            <ul>
              <li>
                Participate in the integration of authentication/authorization
                standards to optimize the design for customer and security
                acceptance.
              </li>
              <li>
                Lead a scrum team, advocating the learning of the engineers.
                Participate in design sessions and code/program design/review.
              </li>
              <li>
                To be part of all aspects of a project lifecycle (requirements,
                analysis, design, code, test, implementation and production
                handover).
              </li>
            </ul>
          </div>
        </div>
        <div class="resume-item">
          <h4>Software Technical Lead</h4>
          <h5>2014 - 2017</h5>
          <p><em>Ministerio de Educación Nacional</em></p>
          <div>
            <ul>
              <li>
                Build solutions to problems that interrupt availability,
                performance, and stability in systems..
              </li>
              <li>
                Design and develop onpremise and cloud components to support
                scalability, high availability and performance.
              </li>
            </ul>
          </div>
        </div>
        <div class="resume-item">
          <h4>Certificates</h4>
          <div>
            <ul>
              <li>
                <a href="https://coursera.org/share/1e72994940a41c7d001de414f7fd11b0">Estilos de Liderazgo: opciones para avanzar en desafíos complejos</a>
              </li>
              <li>
                <a href="https://www.coursera.org/account/accomplishments/verify/8QKZND4V6FP2">Developing a Google SRE Culture</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1xXvwCriQsJJ5zMbz-QUHvfIjDOx6UX7r/view?usp=sharing">Writing Professional English</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1QcbBglWmByIhUcnpm_G7yJQYX_As7rRP/view?usp=sharing">Attendant "Chaos Engineering Bootcamp"</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1GI_CCNn9hGGO1D2pmjAU83q0BtZzORl3/view?usp=sharing">Attendant "El Origen de las Especies" - Universidad Complutense</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1Gghc__Iahg183YUWJQBDHE7DvNO0je12/view?usp=sharing">AZ-900 Microsoft Azure Fundamentals</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1-El1JuQXv6AXz4CfEZ9UI8n8GBu7nXfQ/view?usp=sharing">Speaker Campus Party Colombia</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1KQWudQK4yJGL6nttR5rfZ0B7CamZLLG2/view?usp=sharing">Speaker CiberXChange</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1s_fo0WJi6dh3bRWPA20mwWv5Hrx0DZh2/view?usp=sharing">Speaker Virtual Educa</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1MUhk1oiOoj2qSBGlUIZQXGfl6uvASAcU/view?usp=sharing">Speaker Xellenttro DOIS</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Resume Section -->
