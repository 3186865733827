<header id="header" class="header-tops">
  <div class="container">
    <h1><a href="index.html">Yury Niño Roa</a></h1>
    <h2>I'm Site Reliability Engineer and Chaos Engineer Advocate.</h2>

    <nav class="nav-menu d-none d-lg-block">
      <ul>
        <li class="active"><a href="#header">Home</a></li>
        <li><a href="#resume">Resume</a></li>
        <li><a href="#conferences">Conferences</a></li>
        <li><a href="#publications">Publications</a></li>
        <li><a href="#drawing">Drawings</a></li>
        <li><a href="#quotes">Quotes</a></li>
      </ul>
    </nav>
    <!-- .nav-menu -->

    <div class="social-links">
      <a href="https://www.linkedin.com/in/yurynino/" class="linkedin"
        ><i class="icofont-linkedin"></i
      ></a>
      <a href="https://twitter.com/yurynino" class="twitter"
        ><i class="icofont-twitter"></i
      ></a>
      <a href="https://speakerdeck.com/yurynino" class="speaker"
        ><i class="icofont-play-pause"></i
      ></a>
      <a href="https://medium.com/@yurynino" class="medium"
        ><i class="icofont-medium">M</i></a
      >
      <a href="https://github.com/yurynino" class="github"
        ><i class="icofont-archive"></i
      ></a>
      <a href="mailto:yury.nino.roa@gmail.com" class="email"
        ><i class="icofont-email"></i
      ></a>
      <a
        href="https://www.youtube.com/channel/UCfh20_H-mXacHziw2EJhfHQ"
        class="email"
        ><i class="icofont-youtube-play"></i
      ></a>
    </div>
  </div>
</header>

<!-- ======= Sections ======= -->
<app-resume></app-resume>
<app-conferences></app-conferences>
<app-publications></app-publications>
<app-drawing></app-drawing>
<app-quotes></app-quotes>
